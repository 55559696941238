import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import { connect } from "react-redux";
import FilterPanel from "../../Components/FilterPanel";
import ItemBrowser from "../../Components/ItemBrowser";
import GridItem from "../../Components/ArticleGridItem";
import ListItem from "../../Components/ArticleListItem";
import Paging from "../../Components/Paging";
import Sorting from "../../Components/Sorting";
import Searching from "../../Components/Searching";
import Breadcrumbs from "../../Components/Breadcrumbs";
import AppConfig from "../../Config";
import labels from "../../Domain/ArticleTree";

class ArticleListingPage extends Component {
  render() {
    let Item =
      AppConfig.articleListing.layoutType === "grid" ? GridItem : ListItem;
    let { categoryId, appActions, navActions } = this.props;

    return (
      <Wrapper>
        <div className="articles-filter">
          <FilterPanel
            labels={labels}
            activeFilter={categoryId}
            onFilterItemClick={updateLabel =>
              navActions.push(`/articles/${updateLabel}`)
            }
          />
        </div>

        <ItemBrowser
          label={categoryId}
          navPush={navActions.push}
          limit={4}
          fetchItems={appActions.fetchArticles}
        >
          {({ mounted, loading, data, page, sort, search }) => {
            return (
              <div className="articles-content">
                <div className="articles-breadcrumbs">
                  <Breadcrumbs pathPrefix="/articles" label={categoryId} />
                </div>

                <div className="articles-toolbar">
                  <div className="articles-search">
                    <Searching search={search} />
                  </div>

                  {sort && (
                    <div className="articles-sort">
                      <Sorting sort={sort} />
                    </div>
                  )}
                </div>

                <div className="articles-collection">
                  {data &&
                    data.map(d => (
                      <Item
                        key={d.id}
                        item={d}
                        onClick={() => navActions.push(`/article/?id=${d.id}`)}
                      />
                    ))}
                </div>

                {page.pages && (
                  <div className="articles-pager">
                    <Paging page={page} />
                  </div>
                )}
              </div>
            );
          }}
        </ItemBrowser>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #e6e6e6;
  flex-direction: row-reverse;

  & > .articles-filter {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
  }

  & > .articles-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .articles-breadcrumbs {
    }

    .articles-toolbar {
      display: flex;
      align-items: center;
    }

    .articles-search {
      height: 24px;
    }

    .articles-sort {
    }

    .articles-collection {
      ${AppConfig.articleListing.layoutType === "grid" &&
        `
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      `}
    }

    .articles-pager {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      categoryId: ownProps.pageContext.categoryId
    }),
    ActionCreator
  )(ArticleListingPage)
);
