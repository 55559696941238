import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "../Components/Widget2";
import * as Icon from "../Components/Icon";
import { dateFormat } from "../Utils/DateUtil";

class ArticleListItem extends Component {
  render() {
    let { css, item, onClick } = this.props;

    return (
      <Wrapper css={css} onClick={onClick}>
        <DetailContainer>
          <div className="text">
            <h1>{item.name || "---"}</h1>
            {/* <p>
              {item.description.length > 310
                ? item.description.slice(0, 310) + "..."
                : item.description}
            </p> */}
          </div>
          <Widget.Col>
            <h1>{item.author || "---"}</h1>
            <div className="message">
              <span>{dateFormat(item.created)}</span>
              <div>
                <Icon.Favorite
                  color="#BF6C6C"
                  style={{ marginRight: 2, width: 20, height: 20 }}
                />
                <span>{item.favorite}</span>
              </div>
            </div>
          </Widget.Col>
        </DetailContainer>
        <Image img={item.image} />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  max-height: 265px;
  background-color: white;
  cursor: pointer;

  ${props => props.css};
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 25px;

  & h1 {
    font-weight: 400px;
    font-size: 14px;
    color: #4e4e4e;
  }

  & .text {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    & > p {
      max-width: 530px;
      white-space: pre-wrap;
      font-size: 14px;
      color: #4e4e4e;

      @media screen and (max-width: 414px) {
        display: none;
      }
    }
  }

  & .message {
    display: flex;
    justify-content: space-between;

    & > span {
      font-size: 14px;
      color: #bfbfbf;
    }

    & > div {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #4e4e4e;
    }

    @media screen and (max-width: 414px) {
      flex-wrap: wrap;
    }
  }
`;

const Image = styled.div`
  max-width: 400px;
  min-height: 265px;
  width: 100%;

  background: lightgray;
  background-image: url(${props =>
    props.img || "/images/sample/article-sample.jpg"});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 768px) {
    max-width: 250px;
    min-height: 166px;
  }

  @media screen and (max-width: 414px) {
    max-width: 150px;
    min-height: 100px;
  }

  @media screen and (max-width: 320px) {
    max-width: 120px;
    min-height: 80px;
  }
`;

export default ArticleListItem;
